import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  Avatar, Button, TextField, Link, Grid, Box, Typography, Container, Alert
} from '@mui/material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  
  // Enviar evento de pageview quando o componente montar
  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: 'Login',
        page_location: window.location.href,
        page_path: '/login',
        send_to: 'G-EZ1Y67V4W2'
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Evento do GA - Tentativa de login
    if (window.gtag) {
      window.gtag('event', 'login_attempt', {
        'event_category': 'engagement',
        'event_label': 'login_form_submit'
      });
    }
    
    // Validação dos campos
    if (!email || !password) {
      if (window.gtag) {
        window.gtag('event', 'login_error', {
          'event_category': 'error',
          'event_label': 'missing_fields'
        });
      }
      return setError('Por favor, preencha todos os campos.');
    }
    
    try {
      setError('');
      setLoading(true);
      
      const user = await login(email, password);
      
      // Evento do GA - Login com sucesso
      if (window.gtag) {
        window.gtag('event', 'login_success', {
          'event_category': 'engagement',
          'event_label': 'user_logged_in',
          'user_type': user.role
        });
      }
      
      // Pequeno atraso para garantir que todos os estados foram atualizados
      setTimeout(() => {
        // Redirecionar com base no papel do usuário
        if (user.role === 'admin') {
          navigate('/admin', { replace: true });
        } else {
          navigate('/area-membro', { replace: true });
        }
        setLoading(false);
      }, 100);
    } catch (err) {
      // Evento do GA - Erro de login
      if (window.gtag) {
        window.gtag('event', 'login_error', {
          'event_category': 'error',
          'event_label': 'authentication_failed',
          'error_message': err.message || 'Erro desconhecido'
        });
      }
      
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
            {error}
          </Alert>
        )}
        
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? 'Entrando...' : 'Entrar'}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Esqueceu a senha?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/register" variant="body2">
                Não tem uma conta? Cadastre-se
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
