import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { 
  Typography, Button, Grid, Card, CardContent, Box, Container, 
  Stack, Divider, Paper, Avatar, List, ListItem, ListItemIcon, 
  ListItemText, useTheme, useMediaQuery, Rating,
  MobileStepper, TextField, Snackbar, Alert
} from '@mui/material';
import { 
  People, AttachMoney, Event, Message, ArrowUpward, 
  ChevronRight, CheckCircle, TrendingUp, CreditCard, 
  Smartphone, Analytics, Diversity3, Groups, Speed,
  KeyboardArrowLeft, KeyboardArrowRight
} from '@mui/icons-material';

const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = useState(0);
  
  // Estados para o formulário de lead
  const [leadData, setLeadData] = useState({
    churchName: '',
    responsibleName: '',
    whatsapp: '',
    email: ''
  });
  const [formErrors, setFormErrors] = useState({
    churchName: false,
    responsibleName: false,
    whatsapp: false,
    email: false
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // Estado para lista de igrejas cadastradas
  const [churches, setChurches] = useState([]);
  const [loadingChurches, setLoadingChurches] = useState(true);
  
  // Enviar evento de pageview quando o componente montar
  useEffect(() => {
    if (window.gtag) {
      // Evento de pageview
      window.gtag('event', 'page_view', {
        page_title: 'Homepage',
        page_location: window.location.href,
        page_path: '/',
        send_to: 'G-EZ1Y67V4W2'
      });
      
      // Evento para usuários que permanecem mais de 30 segundos na página
      const timeoutId = setTimeout(() => {
        window.gtag('event', 'time_on_page', {
          'event_category': 'engagement',
          'event_label': 'more_than_30_seconds',
          'value': 30
        });
      }, 30000);
      
      // Configurar detecção de scroll profundo
      let maxScrollDepth = 0;
      let scrollEventSent = false;
      
      const handleScroll = () => {
        // Calcula a porcentagem de scroll
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = (scrollTop / scrollHeight) * 100;
        
        // Atualiza a profundidade máxima de scroll
        if (scrollPercent > maxScrollDepth) {
          maxScrollDepth = scrollPercent;
          
          // Enviar evento quando o usuário rolar mais de 70% da página
          if (maxScrollDepth > 70 && !scrollEventSent) {
            window.gtag('event', 'scroll_depth', {
              'event_category': 'engagement',
              'event_label': 'deep_scroll',
              'value': Math.round(maxScrollDepth)
            });
            scrollEventSent = true;
          }
        }
      };
      
      // Detectar quando o usuário tenta sair da página (bounce tracking)
      const handleBeforeUnload = (e) => {
        // Enviar evento de "tentativa de saída" para o GA
        window.gtag('event', 'exit_intent', {
          'event_category': 'engagement',
          'event_label': 'user_leaving_page',
          'non_interaction': true
        });
      };
      
      // Adicionar listeners
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('beforeunload', handleBeforeUnload);
      
      // Limpeza
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, []);

  // Função para atualizar os dados do formulário
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Rastrear quando o usuário começa a preencher um campo (apenas na primeira interação)
    if (!leadData[name] && value.length === 1 && window.gtag) {
      window.gtag('event', 'form_field_interaction', {
        'event_category': 'engagement',
        'event_label': `field_${name}_started`,
        'field_name': name
      });
    }
    
    // Aplicar formatação especial para o campo de WhatsApp
    if (name === 'whatsapp') {
      // Remove tudo que não for dígito
      const numbersOnly = value.replace(/\D/g, '');
      
      // Aplica máscara conforme a quantidade de dígitos
      let formattedValue = '';
      if (numbersOnly.length <= 2) {
        formattedValue = numbersOnly;
      } else if (numbersOnly.length <= 7) {
        formattedValue = `(${numbersOnly.substring(0, 2)}) ${numbersOnly.substring(2)}`;
      } else if (numbersOnly.length <= 11) {
        formattedValue = `(${numbersOnly.substring(0, 2)}) ${numbersOnly.substring(2, 7)}-${numbersOnly.substring(7)}`;
      } else {
        formattedValue = `+${numbersOnly.substring(0, 2)} (${numbersOnly.substring(2, 4)}) ${numbersOnly.substring(4, 9)}-${numbersOnly.substring(9, 13)}`;
      }
      
      // Se o usuário completou o telefone, enviar evento
      if (numbersOnly.length >= 11 && leadData.whatsapp.replace(/\D/g, '').length < 11 && window.gtag) {
        window.gtag('event', 'form_field_complete', {
          'event_category': 'engagement',
          'event_label': 'whatsapp_complete',
          'field_name': 'whatsapp'
        });
      }
      
      setLeadData(prev => ({
        ...prev,
        [name]: formattedValue
      }));
    } else {
      // Para outros campos, atualiza normalmente
      setLeadData(prev => ({
        ...prev,
        [name]: value
      }));
      
      // Se for o campo de email e contiver @ e ., considerar como potencialmente completo
      if (name === 'email' && value.includes('@') && value.includes('.') && window.gtag) {
        window.gtag('event', 'form_field_complete', {
          'event_category': 'engagement',
          'event_label': 'email_complete',
          'field_name': 'email'
        });
      }
    }
    
    // Remove o erro quando o usuário começa a digitar
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: false
      }));
    }
  };

  // Validação do formulário
  const validateForm = () => {
    // Valida o número de telefone/WhatsApp após remover formatação
    const validateWhatsApp = (whatsapp) => {
      const digitsOnly = whatsapp.replace(/\D/g, '');
      
      // Deve ter entre 10 e 13 dígitos (com ou sem código de país)
      if (digitsOnly.length < 10 || digitsOnly.length > 13) {
        return false;
      }
      
      // Se começar com o código do Brasil (+55), deve ter entre 12 e 13 dígitos
      if (digitsOnly.startsWith('55') && (digitsOnly.length < 12 || digitsOnly.length > 13)) {
        return false;
      }
      
      // Para outros formatos, verificar se tem pelo menos 10 dígitos (DDD + número)
      return true;
    };
    
    // Valida o email
    const validateEmail = (email) => {
      if (!email) return false;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    
    const errors = {
      churchName: !leadData.churchName,
      responsibleName: !leadData.responsibleName,
      whatsapp: !leadData.whatsapp || !validateWhatsApp(leadData.whatsapp),
      email: !leadData.email || !validateEmail(leadData.email)
    };
    
    setFormErrors(errors);
    return !Object.values(errors).some(error => error);
  };

  // Função para buscar igrejas cadastradas
  useEffect(() => {
    const fetchChurches = async () => {
      try {
        setLoadingChurches(true);
        const response = await api.get('/api/churches');
        setChurches(response.data.churches || []);
      } catch (err) {
        console.error('Erro ao buscar igrejas:', err);
      } finally {
        setLoadingChurches(false);
      }
    };

    fetchChurches();
  }, []);

  // Função para salvar o lead
  const saveLead = async () => {
    if (!validateForm()) {
      return;
    }
    
    try {
      // Evento do GA - Formulário de lead enviado
      if (window.gtag) {
        window.gtag('event', 'form_submission', {
          'event_category': 'lead',
          'event_label': 'homepage_lead',
          'church_name': leadData.churchName
        });
      }
      
      // Salvar o lead com a API existente
      await api.post('/api/leads', {
        ...leadData,
        // Garantir que o WhatsApp está apenas com números
        whatsapp: leadData.whatsapp.replace(/\D/g, ''),
        timestamp: new Date(),
      });
      
      // Evento do GA - Lead criado com sucesso
      if (window.gtag) {
        window.gtag('event', 'lead_success', {
          'event_category': 'lead',
          'event_label': 'lead_created'
        });
      }
      
      // Feedback de sucesso
      setSnackbar({
        open: true,
        message: 'Cadastro realizado com sucesso! Redirecionando para o registro...',
        severity: 'success'
      });
      
      // Armazenar dados do lead no localStorage para preencher o formulário de registro
      localStorage.setItem('leadData', JSON.stringify({
        churchName: leadData.churchName,
        name: leadData.responsibleName,
        email: leadData.email,
        phone: leadData.whatsapp
      }));
      
      // Limpa o formulário
      setLeadData({
        churchName: '',
        responsibleName: '',
        whatsapp: '',
        email: ''
      });
      
      // Redirecionar para a página de registro após 1.5 segundos
      setTimeout(() => {
        navigate('/register');
      }, 1500);
      
    } catch (error) {
      console.error('Erro ao salvar lead:', error);
      
      // Evento do GA - Erro ao criar lead
      if (window.gtag) {
        window.gtag('event', 'lead_error', {
          'event_category': 'error',
          'event_label': 'lead_submission_failed',
          'error_message': error.message || 'Erro desconhecido'
        });
      }
      
      setSnackbar({
        open: true,
        message: 'Erro ao realizar cadastro. Por favor, tente novamente.',
        severity: 'error'
      });
    }
  };

  // Fecha a notificação de feedback
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };
  
  // Função para rolar suavemente até o formulário de lead
  const scrollToLeadForm = (event) => {
    event.preventDefault();
    const leadFormElement = document.getElementById('lead-form');
    
    // Evento do GA - Clique em CTA
    if (window.gtag) {
      window.gtag('event', 'cta_click', {
        'event_category': 'engagement',
        'event_label': 'scroll_to_form',
        'button_location': event.currentTarget.getAttribute('data-location') || 'unknown'
      });
    }
    
    if (leadFormElement) {
      leadFormElement.scrollIntoView({ 
        behavior: 'smooth',
        block: 'center'
      });
    }
  };
  
  const appMockups = [
    {
      label: 'Whatsapp para incentivar a contribuição',
      imgPath: '/images/whats-dizimo.png',
      description: 'Enviamos mensagens automatizadas para os membros incentivando a contribuição através de um link seguro e fácil de usar.'
    },
    {
      label: 'Link de doação ',
      imgPath: '/images/doacao.png',
      description: 'Possibilidade de doação com pagamento via cartão de crédito, boleto e pix. Além de recebimento recorrente de contribuições através da "Contribuição Mensal".'
    },
    {
      label: 'Mensagens de Aniversário',
      imgPath: '/images/whats-aniversario.png',
      description: 'Enviamos mensagens automatizadas para os membros no dia do seu aniversário. Todas mensagens são personalizadas.'
    },
    {
      label: 'Lembretes de contribuição',
      imgPath: '/images/whats-dizimo-reminder.png',
      description: 'Enviamos mensagens automatizadas para os membros que estão há mais de 45, 60 ou 90 dias sem contribuição. Você pode configurar o período de lembrete que desejar.'
    },
    {
      label: 'Lembretes de Eventos',
      imgPath: '/images/whats-evento.png',
      description: 'Deixe os fieis saberem onde e quando estão acontecendo eventos da sua igreja. Enviamos mensagens automatizadas para os membros que estão há mais de 45, 60 ou 90 dias sem contribuir.'
    },
  ];
  
  const maxSteps = appMockups.length;
  
  const handleNext = () => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    
    // Evento do GA - Navegação no carrossel
    if (window.gtag) {
      window.gtag('event', 'carousel_navigation', {
        'event_category': 'engagement',
        'event_label': 'carousel_next',
        'carousel_item': appMockups[nextStep].label
      });
    }
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    setActiveStep(prevStep);
    
    // Evento do GA - Navegação no carrossel
    if (window.gtag) {
      window.gtag('event', 'carousel_navigation', {
        'event_category': 'engagement',
        'event_label': 'carousel_prev',
        'carousel_item': appMockups[prevStep].label
      });
    }
  };

  const testimonials = [
    {
      name: "Pastor Carlos Silva",
      church: "Igreja Renascer",
      text: "Desde que implementamos esta plataforma, nossas doações cresceram 37% e a interação com os membros melhorou significativamente.",
      avatar: "C"
    },
    {
      name: "Pastor João Oliveira",
      church: "Igreja Vida Plena",
      text: "A simplicidade do sistema e a facilidade para os membros contribuírem fez nossa arrecadação aumentar em mais de 40% no primeiro trimestre.",
      avatar: "J"
    },
    {
      name: "Pastora Ana Beatriz",
      church: "Ministério Presença",
      text: "Além do aumento nas contribuições, tivemos um crescimento de 25% na presença dos cultos através do sistema de notificações.",
      avatar: "A"
    }
  ];

  const results = [
    { 
      number: "30%", 
      text: "aumento médio na arrecadação de dízimos e ofertas",
      icon: <TrendingUp fontSize="large" sx={{ color: '#4CAF50' }} />
    },
    { 
      number: "45%", 
      text: "maior engajamento dos membros em atividades e eventos",
      icon: <Groups fontSize="large" sx={{ color: '#2196F3' }} />
    },
    { 
      number: "63%", 
      text: "dos membros utilizam o app para contribuir mensalmente",
      icon: <Smartphone fontSize="large" sx={{ color: '#9c27b0' }} />
    }
  ];
  
  const features = [
    {
      title: 'Sistema de Contribuição Online',
      description: 'Plataforma completa para dízimos e ofertas com diversos meios de pagamento, recibos automáticos e relatórios detalhados.',
      icon: <CreditCard fontSize="large" sx={{ color: '#4CAF50' }} />
    },
    {
      title: 'Gestão de Membros',
      description: 'Cadastro completo, segmentação, histórico de contribuições e comunicação personalizada com sua comunidade.',
      icon: <People fontSize="large" sx={{ color: '#2196F3' }} />
    },
    {
      title: 'Automação de Comunicação',
      description: 'Envios automáticos de lembretes de eventos, aniversários e agradecimentos por contribuições via WhatsApp.',
      icon: <Message fontSize="large" sx={{ color: '#FF9800' }} />
    },
    {
      title: 'Dashboard Analítico',
      description: 'Visualize métricas importantes da sua igreja: contribuições, crescimento, frequência e engajamento dos membros.',
      icon: <Analytics fontSize="large" sx={{ color: '#9C27B0' }} />
    }
  ];

  return (
    <>
      {/* Hero Section - Proposta de Valor Principal */}
      <Box 
       
      >
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Grid container spacing={12} alignItems="center">
            <Grid item xs={12} md={7}>
              <Box sx={{ mb: 2 }}>
                <Typography 
                  variant="overline" 
                  sx={{ 
                    color: '#4CAF50',
                    fontWeight: 600,
                    letterSpacing: 1.5,
                    fontSize: '1rem',
                    display: 'block',
                    mb: 1
                  }}
                >
                  SOLUÇÃO COMPLETA PARA SUA IGREJA
                </Typography>
              </Box>
              
              <Typography 
                variant="h1" 
                component="h1" 
                sx={{ 
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  fontWeight: 800,
                  lineHeight: 1.2,
                  mb: 2,
                  color: '#1A237E',
                  '& span': {
                    color: '#4CAF50',
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 8,
                      left: 0,
                      width: '100%',
                      height: 8,
                      backgroundColor: 'rgba(76, 175, 80, 0.2)',
                      zIndex: -1,
                      borderRadius: 4
                    }
                  }
                }}
              >
                Aumentamos em <span>30%</span> a sua arrecadação de dízimos e ofertas.
              </Typography>
              
              <Typography 
                variant="h4" 
                component="h4" 
                sx={{ 
                  fontSize: { xs: '1.2rem', md: '1.5rem' },
                  fontWeight: 400,
                  color: '#546e7a',
                  mb: 4
                }}
              >
                Mais recursos para sua missão, mais fiéis engajados no propósito.
              </Typography>
              
              <Box sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CheckCircle fontSize="small" sx={{ color: '#4CAF50', mr: 1 }} />
                      <Typography variant="body1" color="text.secondary">
                        Fácil de implementar
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CheckCircle fontSize="small" sx={{ color: '#4CAF50', mr: 1 }} />
                      <Typography variant="body1" color="text.secondary">
                        Segurança garantida
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CheckCircle fontSize="small" sx={{ color: '#4CAF50', mr: 1 }} />
                      <Typography variant="body1" color="text.secondary">
                        Suporte 7 dias por semana
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CheckCircle fontSize="small" sx={{ color: '#4CAF50', mr: 1 }} />
                      <Typography variant="body1" color="text.secondary">
                        Relatórios detalhados
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              
              
              <Box sx={{ mt: 4, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                  Usado por +500 igrejas 
                </Typography>
                <Rating value={5} readOnly size="small" />
                <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                  5.0/5.0 (98 avaliações)
                </Typography>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
              <Box id="lead-form"
                sx={{ 
                  position: 'relative',
                  backgroundColor: 'white',
                  p: 3,
                  borderRadius: 4,
                  boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    right: -15,
                    bottom: -15,
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#E8F5E9',
                    borderRadius: 4,
                    zIndex: -1
                  },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: -15,
                    top: -15,
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#E3F2FD',
                    borderRadius: 4,
                    zIndex: -2
                  }
                }}
              >
                <Typography variant="h5" color="primary.main" sx={{ mb: 3, fontWeight: 600 }}>
                  Preencha os dados para começar sua jornada:
                </Typography>
                
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Nome da Igreja"
                      name="churchName"
                      value={leadData.churchName}
                      onChange={handleInputChange}
                      error={formErrors.churchName}
                      helperText={formErrors.churchName ? "Campo obrigatório" : ""}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#4CAF50',
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#4CAF50',
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Nome do Responsável"
                      name="responsibleName"
                      value={leadData.responsibleName}
                      onChange={handleInputChange}
                      error={formErrors.responsibleName}
                      helperText={formErrors.responsibleName ? "Campo obrigatório" : ""}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#4CAF50',
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#4CAF50',
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="WhatsApp do Responsável"
                      name="whatsapp"
                      value={leadData.whatsapp}
                      onChange={handleInputChange}
                      error={formErrors.whatsapp}
                      helperText={formErrors.whatsapp ? "Número de WhatsApp válido obrigatório" : ""}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#4CAF50',
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#4CAF50',
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email do Responsável"
                      name="email"
                      type="email"
                      value={leadData.email}
                      onChange={handleInputChange}
                      error={formErrors.email}
                      helperText={formErrors.email ? "Email válido obrigatório" : ""}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#4CAF50',
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#4CAF50',
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Button 
                  variant="contained" 
                  size="large" 
                  onClick={(e) => {
                    // Evento do GA - Clique no botão de submissão do formulário
                    if (window.gtag) {
                      window.gtag('event', 'button_click', {
                        'event_category': 'conversion',
                        'event_label': 'lead_form_submit',
                        'form_position': 'hero_section'
                      });
                    }
                    saveLead();
                  }}
                  fullWidth
                  sx={{ 
                    py: 1.5,
                    px: 4,
                    backgroundColor: '#4CAF50',
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    boxShadow: '0 8px 16px rgba(76, 175, 80, 0.3)',
                    '&:hover': {
                      backgroundColor: '#388E3C',
                      boxShadow: '0 8px 20px rgba(76, 175, 80, 0.4)',
                      transform: 'translateY(-2px)'
                    },
                    transition: 'all 0.3s ease'
                  }}
                  endIcon={<ChevronRight />}
                >
                  Comece Agora
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Números e Resultados */}
      <Box sx={{ py: 8}}>
        <Container>
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Typography 
              variant="h3" 
              color="#1A237E"
              component="h2" 
              sx={{ 
                fontWeight: 700,
                mb: 2
              }}
            >
              Resultados Comprovados
            </Typography>
            <Typography 
              variant="h6" 
              color="text."
              sx={{ maxWidth: 700, mx: 'auto' }}
            >
              Nossos clientes experimentam crescimento real em contribuições e engajamento
            </Typography>
          </Box>

          <Grid container spacing={4} justifyContent="center">
            {results.map((result, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 4, 
                    textAlign: 'center',
                    height: '100%',
                    borderRadius: 4,
                    transition: 'transform 0.3s',
                     boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                    '&:hover': {
                      transform: 'translateY(-8px)'
                     
                    }
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    {result.icon}
                  </Box>
                  <Typography 
                    variant="h2" 
                    component="p" 
                    sx={{ 
                      fontWeight: 800,
                      fontSize: { xs: '2.5rem', md: '3rem' },
                      mb: 1 
                    }}
                  >
                    {result.number}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {result.text}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Seção de App Mockups com Carrossel */}
      <Box sx={{ py: 10, backgroundColor: 'white' }}>
        <Container>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={5} sx={{ order: { xs: 2, md: 1 } }}>
              <Typography 
                variant="h3" 
                component="h2" 
                sx={{ 
                  fontWeight: 700,
                  mb: 3,
                  color: '#1A237E'
                }}
              >
                A palavra da sua igreja no meio digital
              </Typography>
              
              <Typography 
                variant="h5" 
                sx={{
                  mb: 2,
                  fontWeight: 600,
                  color: activeStep === 0 ? '#3949AB' : 
                         activeStep === 1 ? '#4CAF50' : 
                         activeStep === 2 ? '#FF9800' : '#9C27B0'
                }}
              >
                {appMockups[activeStep].label}
              </Typography>
              
              <Typography 
                variant="body1" 
                color="text.secondary" 
                paragraph
                sx={{ mb: 4, fontSize: '1.1rem' }}
              >
                {appMockups[activeStep].description}
              </Typography>
              
              {/*<List>
                <ListItem sx={{ p: 0, mb: 2 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <CheckCircle sx={{ color: '#4CAF50' }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Interface intuitiva e fácil de usar" 
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
                <ListItem sx={{ p: 0, mb: 2 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <CheckCircle sx={{ color: '#4CAF50' }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Acesso instantâneo a relatórios detalhados" 
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
                <ListItem sx={{ p: 0, mb: 2 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <CheckCircle sx={{ color: '#4CAF50' }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Notificações inteligentes e lembretes" 
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <CheckCircle sx={{ color: '#4CAF50' }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Funciona em qualquer dispositivo, em qualquer lugar" 
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
              </List>*/}
              
              <Box sx={{ mt: 4 }}>
                <Button 
                  variant="contained" 
                  size="large" 
                  onClick={scrollToLeadForm}
                  data-location="feature_section"
                  sx={{ 
                    py: 1.5,
                    px: 4,
                    backgroundColor: '#4CAF50',
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    boxShadow: '0 8px 16px rgba(76, 175, 80, 0.3)',
                    '&:hover': {
                      backgroundColor: '#388E3C',
                      boxShadow: '0 8px 20px rgba(76, 175, 80, 0.4)',
                      transform: 'translateY(-2px)'
                    },
                    transition: 'all 0.3s ease'
                  }}
                  endIcon={<ChevronRight />}
                >
                  Experimente Agora
                </Button>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={7} sx={{ order: { xs: 1, md: 2 } }}>
              <Box
                sx={{
                  height: { xs: 500, md: 700 },
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: 6,
                  boxShadow: '0 30px 60px rgba(0,0,0,0.15)',
                  bgcolor: '#f8f9fa',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {/* Efeito de moldura de celular */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '80%', md: '65%' },
                    height: { xs: '90%', md: '90%' },
                    borderRadius: '36px',
                    zIndex: 10
                  }}
                >
                  {/* Imagem do app */}
                  <Box
                    component="img"
                    src={appMockups[activeStep].imgPath}
                    alt={appMockups[activeStep].label}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      display: 'block',
                      margin: '0 auto'
                    }}
                  />
                </Box>
                
                {/* Efeitos visuais decorativos */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '10%',
                    right: '15%',
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(76,175,80,0.2) 0%, rgba(129,199,132,0.1) 50%, rgba(200,230,201,0) 70%)',
                    zIndex: 0
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '15%',
                    left: '10%',
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(57,73,171,0.2) 0%, rgba(92,107,192,0.1) 50%, rgba(197,202,233,0) 70%)',
                    zIndex: 0
                  }}
                />
              </Box>
              
              {/* Stepper para navegação do carrossel */}
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  sx={{ 
                    maxWidth: 400, 
                    flexGrow: 1,
                    bgcolor: 'transparent'
                  }}
                  nextButton={
                    <Button 
                      size="small" 
                      onClick={handleNext} 
                      disabled={activeStep === maxSteps - 1}
                      sx={{ 
                        color: '#4CAF50',
                        '&.Mui-disabled': {
                          color: 'rgba(0, 0, 0, 0.26)'
                        } 
                      }}
                    >
                      Próximo
                      <KeyboardArrowRight />
                    </Button>
                  }
                  backButton={
                    <Button 
                      size="small" 
                      onClick={handleBack} 
                      disabled={activeStep === 0}
                      sx={{ 
                        color: '#4CAF50',
                        '&.Mui-disabled': {
                          color: 'rgba(0, 0, 0, 0.26)'
                        } 
                      }}
                    >
                      <KeyboardArrowLeft />
                      Anterior
                    </Button>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Recursos/Características */}
      <Box sx={{ py: 8 }}>
        <Container>
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Typography 
              variant="h3" 
              component="h2" 
              sx={{ 
                fontWeight: 700,
                mb: 2
              }}
            >
              Uma Plataforma Completa 
            </Typography>
            <Typography 
              variant="h6" 
              color="text.secondary"
              sx={{ maxWidth: 700, mx: 'auto' }}
            >
              Tudo o que sua igreja precisa para crescer financeiramente e espiritualmente com o Povo Fiel
            </Typography>
          </Box>

          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card 
                  elevation={0} 
                  sx={{ 
                    p: 2,
                    height: '100%',
                    borderRadius: 4,
                    transition: 'all 0.3s',
                    '&:hover': {
                      boxShadow: '0 8px 24px rgba(0,0,0,0.12)'
                    }
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', mb: 2 }}>
                      <Box sx={{ mr: 2 }}>
                        {feature.icon}
                      </Box>
                      <Typography 
                        variant="h5" 
                        component="h3" 
                        sx={{ fontWeight: 600 }}
                      >
                        {feature.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="text.secondary">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid sx={{ mt: 4,display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', }}   >
                <Button 
                  variant="contained" 
                  size="large" 
                  onClick={scrollToLeadForm}
                  data-location="feature_section"
                  sx={{ 
                    py: 1.5,
                    px: 4,
                    backgroundColor: '#4CAF50',
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    boxShadow: '0 8px 16px rgba(76, 175, 80, 0.3)',
                    '&:hover': {
                      backgroundColor: '#388E3C',
                      boxShadow: '0 8px 20px rgba(76, 175, 80, 0.4)',
                      transform: 'translateY(-2px)'
                    },
                    transition: 'all 0.3s ease'
                  }}
                  endIcon={<ChevronRight />}
                >
                  Experimente Agora
                </Button>
              </Grid>
        </Container>
      </Box>

      {/* Seção de Recursos Principais - Visual Interativo */}
      <Box sx={{ py: 12, bgcolor: '#fafbfc' }}>
        <Container>
          <Box sx={{ textAlign: 'center', mb: 8 }}>
            <Typography 
              variant="h3" 
              component="h2" 
              sx={{ 
                fontWeight: 700,
                mb: 2
              }}
            >
              Recursos Principais
            </Typography>
            <Typography 
              variant="h6" 
              color="text.secondary"
              sx={{ maxWidth: 700, mx: 'auto' }}
            >
              Conheça como o Povo Fiel pode transformar a gestão da sua igreja
            </Typography>
          </Box>

          {/* Recurso 1: Gestão de Fiéis */}
          <Grid 
            container 
            spacing={4} 
            alignItems="center" 
            sx={{ 
              mb: 12,
              position: 'relative',
            }}
          >
            <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative' }}>
                <Box 
                  sx={{ 
                    position: 'absolute', 
                    top: -25, 
                    left: -25,
                    width: 50,
                    height: 50,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#3949AB',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
                    zIndex: 2
                  }}
                >
                  1
                </Box>
                <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 700, color: '#1A237E' }}>
                  Gestão Completa de Membros
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 3, fontWeight: 500 }}>
                  Centralize todas as informações dos fiéis em um único lugar com o Povo Fiel
                </Typography>
                <Typography variant="body1" paragraph color="text.secondary" sx={{ mb: 3 }}>
                  Com nossa plataforma, você terá uma visão 360° de cada membro da igreja, permitindo acompanhar 
                  sua jornada espiritual, participação em eventos, contribuições e muito mais.
                </Typography>
                
                <List>
                  <ListItem sx={{ pl: 0, pb: 1 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Cadastros completos com campos personalizáveis" 
                      secondary="Adapte as informações às necessidades da sua igreja"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0, pb: 1 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Segmentação inteligente de membros" 
                      secondary="Crie grupos por idade, localização, ministérios ou qualquer atributo"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Histórico completo de interações" 
                      secondary="Visualize toda a jornada do membro na comunidade"
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative' }}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: 4,
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
                    transform: 'perspective(1000px) rotateY(-5deg) rotateX(5deg)',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '40px',
                      background: 'linear-gradient(to right, #3949AB, #5C6BC0)',
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      zIndex: 1
                    }
                  }}
                >
                  <Box
                    component="img"
                    src="/images/gestao-fieis.png"
                    alt="Dashboard de gestão de membros"
                    sx={{
                      width: '100%',
                      display: 'block'
                    }}
                  />
                  
                  {/* Elementos decorativos - simulação de UI */}
                  <Box sx={{ 
                    position: 'absolute', 
                    top: 8, 
                    left: 16, 
                    display: 'flex', 
                    gap: 1,
                    zIndex: 2 
                  }}>
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#f44336' }} />
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#ff9800' }} />
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#4caf50' }} />
                  </Box>
                </Paper>
                
                {/* Elemento visual decorativo */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: -30,
                    right: -30,
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(57,73,171,0.1) 0%, rgba(26,35,126,0.05) 70%)',
                    zIndex: -1
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Recurso 2: Gestão Financeira */}
          <Grid 
            container 
            spacing={4} 
            alignItems="center" 
            sx={{ 
              mb: 12,
              position: 'relative'
            }}
          >
            <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
              <Box sx={{ position: 'relative' }}>
                
                <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 700, color: '#2E7D32' }}>
                  Gestão Financeira Inteligente
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 3, fontWeight: 500 }}>
                  Aumente as contribuições e simplifique a administração financeira com o Povo Fiel
                </Typography>
                <Typography variant="body1" paragraph color="text.secondary" sx={{ mb: 3 }}>
                  Oferecemos uma solução completa para gerenciar dízimos e ofertas, com múltiplas opções de pagamento, 
                  relatórios detalhados e total transparência para os membros da igreja.
                </Typography>
                
                <List>
                  <ListItem sx={{ pl: 0, pb: 1 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Múltiplas formas de pagamento" 
                      secondary="PIX, cartão, boleto e transferência bancária integrados"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0, pb: 1 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Painéis analíticos com tendências e projeções" 
                      secondary="Visualize padrões de contribuição e planeje melhor"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Recibos e comprovantes automáticos" 
                      secondary="Envie agradecimentos personalizados após cada contribuição"
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
              <Box sx={{ position: 'relative' }}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: 4,
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
                    transform: 'perspective(1000px) rotateY(5deg) rotateX(5deg)',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '40px',
                      background: 'linear-gradient(to right, #4CAF50, #81C784)',
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      zIndex: 1
                    }
                  }}
                >
                  <Box
                    component="img"
                    src="/images/dizimo.png"
                    alt="Dashboard financeiro"
                    sx={{
                      width: '100%',
                      display: 'block'
                    }}
                  />
                  
                  {/* Elementos decorativos - simulação de UI */}
                  <Box sx={{ 
                    position: 'absolute', 
                    top: 8, 
                    left: 16, 
                    display: 'flex', 
                    gap: 1,
                    zIndex: 2 
                  }}>
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#f44336' }} />
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#ff9800' }} />
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#4caf50' }} />
                  </Box>
                </Paper>
                
                {/* Elemento visual decorativo */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: -30,
                    left: -30,
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(76,175,80,0.1) 0%, rgba(46,125,50,0.05) 70%)',
                    zIndex: -1
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Recurso 3: Gestão de Eventos */}
          <Grid 
            container 
            spacing={4} 
            alignItems="center" 
            sx={{ 
              mb: 12,
              position: 'relative',
            }}
          >
            <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative' }}>
                
                <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 700, color: '#E65100' }}>
                  Gestão Avançada de Eventos
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 3, fontWeight: 500 }}>
                  Organize eventos e maximize a participação dos membros com o Povo Fiel
                </Typography>
                <Typography variant="body1" paragraph color="text.secondary" sx={{ mb: 3 }}>
                  Crie, gerencie e promova eventos de forma eficiente. Nossa plataforma permite o controle completo de inscrições, 
                  check-ins e envio de lembretes automáticos para aumentar a presença.
                </Typography>
                
                <List>
                  <ListItem sx={{ pl: 0, pb: 1 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Criação de eventos com páginas personalizadas" 
                      secondary="Inscrições online com formulários customizáveis"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0, pb: 1 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Sistema de lembretes automáticos via WhatsApp" 
                      secondary="Aumente a presença com notificações inteligentes"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Controle de presença e análise pós-evento" 
                      secondary="Relatórios completos sobre participação e engajamento"
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative' }}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: 4,
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
                    transform: 'perspective(1000px) rotateY(-5deg) rotateX(5deg)',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '40px',
                      background: 'linear-gradient(to right, #FF9800, #FFB74D)',
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      zIndex: 1
                    }
                  }}
                >
                  <Box
                    component="img"
                    src="/images/eventos.jpg"
                    alt="Sistema de gestão de eventos"
                    sx={{
                      width: '100%',
                      display: 'block'
                    }}
                  />
                  
                  {/* Elementos decorativos - simulação de UI */}
                  <Box sx={{ 
                    position: 'absolute', 
                    top: 8, 
                    left: 16, 
                    display: 'flex', 
                    gap: 1,
                    zIndex: 2 
                  }}>
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#f44336' }} />
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#ff9800' }} />
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#4caf50' }} />
                  </Box>
                </Paper>
                
                {/* Elemento visual decorativo */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: -30,
                    right: -30,
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(255,152,0,0.1) 0%, rgba(230,81,0,0.05) 70%)',
                    zIndex: -1
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Recurso 4: Comunicação por WhatsApp */}
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
              <Box sx={{ position: 'relative' }}>
                
                <Typography variant="h4" component="h3" gutterBottom sx={{ fontWeight: 700, color: '#6A1B9A' }}>
                  Comunicação Automatizada
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 3, fontWeight: 500 }}>
                  Mantenha sua comunidade engajada com mensagens personalizadas do Povo Fiel
                </Typography>
                <Typography variant="body1" paragraph color="text.secondary" sx={{ mb: 3 }}>
                  Nossa integração com WhatsApp permite enviar mensagens automatizadas para sua comunidade, 
                  desde lembretes de eventos até felicitações de aniversário e agradecimentos por contribuições.
                </Typography>
                
                <List>
                  <ListItem sx={{ pl: 0, pb: 1 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Mensagens personalizadas em massa" 
                      secondary="Comunique-se com grupos específicos em poucos cliques"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0, pb: 1 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Agendamento prévio de comunicações" 
                      secondary="Programação de envios para datas e horários específicos"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0 }}>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: '#4CAF50' }} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Automações inteligentes baseadas em comportamento" 
                      secondary="Envios acionados por ações como contribuições ou inscrições"
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
              <Box sx={{ position: 'relative' }}>
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: 4,
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: '0 20px 40px rgba(0,0,0,0.15)',
                    transform: 'perspective(1000px) rotateY(5deg) rotateX(5deg)',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '40px',
                      background: 'linear-gradient(to right, #9C27B0, #CE93D8)',
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      zIndex: 1
                    }
                  }}
                >
                  <Box
                    component="img"
                    src="/images/comunicacao.jpg"
                    alt="Sistema de comunicação automatizada"
                    sx={{
                      width: '100%',
                      display: 'block'
                    }}
                  />
                  
                  {/* Elementos decorativos - simulação de UI */}
                  <Box sx={{ 
                    position: 'absolute', 
                    top: 8, 
                    left: 16, 
                    display: 'flex', 
                    gap: 1,
                    zIndex: 2 
                  }}>
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#f44336' }} />
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#ff9800' }} />
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#4caf50' }} />
                  </Box>
                </Paper>
                
                {/* Elemento visual decorativo */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: -30,
                    left: -30,
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(156,39,176,0.1) 0%, rgba(106,27,154,0.05) 70%)',
                    zIndex: -1
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Depoimentos */}
      <Box sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
        <Container>
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Typography 
              variant="h3" 
              component="h2" 
              sx={{ 
                fontWeight: 700,
                mb: 2
              }}
            >
              O Que Os Pastores Dizem
            </Typography>
            <Typography 
              variant="h6" 
              color="text.secondary"
              sx={{ maxWidth: 700, mx: 'auto' }}
            >
              Histórias reais de crescimento e transformação com o Povo Fiel
            </Typography>
          </Box>

          <Grid container spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card 
                  elevation={0} 
                  sx={{ 
                    p: 2,
                    height: '100%',
                    borderRadius: 4,
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 30,
                      left: 30,
                      width: 40,
                      height: 40,
                      backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'%234CAF50\' width=\'40px\' height=\'40px\'%3E%3Cpath d=\'M0 0h24v24H0V0z\' fill=\'none\'/%3E%3Cpath d=\'M7.17 17c.51 0 .98-.29 1.2-.74l1.42-2.84c.14-.28.21-.58.21-.89V8c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h2l-1.03 2.06c-.45.89.2 1.94 1.2 1.94zm10 0c.51 0 .98-.29 1.2-.74l1.42-2.84c.14-.28.21-.58.21-.89V8c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h2l-1.03 2.06c-.45.89.2 1.94 1.2 1.94z\'/%3E%3C/svg%3E")',
                      opacity: 0.2,
                      zIndex: 0
                    }
                  }}
                >
                  <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                    <Typography 
                      variant="body1" 
                      paragraph 
                      sx={{ 
                        fontStyle: 'italic',
                        mb: 3
                      }}
                    >
                      "{testimonial.text}"
                    </Typography>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar 
                        sx={{ 
                          mr: 2, 
                          bgcolor: index === 0 ? '#4CAF50' : index === 1 ? '#2196F3' : '#FF9800'
                        }}
                      >
                        {testimonial.avatar}
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                          {testimonial.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {testimonial.church}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Call-to-Action */}
      <Box 
        sx={{ 
          py: 10,
          backgroundImage: 'linear-gradient(135deg, #4CAF50 0%, #2E7D32 100%)',
          color: 'white',
          textAlign: 'center'
        }}
      >
        <Container maxWidth="md">
          <Typography 
            variant="h3" 
            component="h2" 
            sx={{ 
              fontWeight: 700,
              mb: 3
            }}
          >
            Pronto para Transformar sua Igreja?
          </Typography>
          
          <Typography 
            variant="h6" 
            component="p" 
            sx={{ 
              mb: 4,
              opacity: 0.9
            }}
          >
            Junte-se a centenas de igrejas que já estão crescendo com o Povo Fiel
          </Typography>
          
          <Button 
            variant="contained" 
            size="large" 
            onClick={scrollToLeadForm}
            data-location="cta_section_bottom"
            sx={{ 
              py: 2,
              px: 6,
              backgroundColor: 'white',
              color: '#4CAF50',
              fontSize: '1.2rem',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: '#f5f5f5',
                transform: 'translateY(-2px)'
              },
              transition: 'all 0.3s ease'
            }}
            endIcon={<ArrowUpward />}
          >
            Experimente Agora
          </Button>
        </Container>
      </Box>

      {/* Notificação de feedback */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Home;